export default (element) => ({
	data() {
		return {
			elementInFocus: NaN,
			previouslyActiveElement: "",
		};
	},
	methods: {
		handleTabtrapping(isOpen) {
			if (isOpen) {
				this.previouslyActiveElement = document.activeElement;
				document.addEventListener("keydown", this.tabTrapping);
			} else {
				document.removeEventListener("keydown", this.tabTrapping);
				this.elementInFocus = NaN;
			}
		},
		tabTrapping(event) {
			if (event.keyCode === 9) {
				const parentNode = document.getElementById(element);
				if (parentNode) {
					event.stopPropagation();
					event.preventDefault();
					const focusable = parentNode.querySelectorAll(
						'a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable], audio[controls], video[controls], summary, [tabindex^="0"], [tabindex^="1"], [tabindex^="2"], [tabindex^="3"], [tabindex^="4"], [tabindex^="5"], [tabindex^="6"], [tabindex^="7"], [tabindex^="8"], [tabindex^="9"]',
					);
					if (!isNaN(this.elementInFocus)) {
						if (event.shiftKey) {
							this.elementInFocus = this.elementInFocus === 0 ? focusable.length - 1 : this.elementInFocus - 1;
						} else {
							this.elementInFocus = this.elementInFocus >= focusable.length - 1 ? 0 : this.elementInFocus + 1;
						}
					} else {
						this.elementInFocus = 0;
					}
					focusable[this.elementInFocus].focus();
				}
			}
		},
	},
});
